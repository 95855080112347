#root .sidebar {
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.125);

  @media screen and (max-width: 1200px) {
    position: absolute;
    z-index: 5;
  }

  .ant-layout-sider-zero-width-trigger {
    top: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .menu {
    height: 100%;
    border-right: 0;

    label {
      input[type="file"] {
        display: none;
      }
      a {
        margin-right: 0.5em;
      }
    }

    .group {
      height: calc(100% - 3.5em);
      .ant-menu-item-group-title {
        padding-top: 1em;
        .ant-spin .ant-spin-dot {
          top: 50%;
          left: 100%;
          margin: -15px;
        }
      }
      .ant-menu-item-group-list {
        height: calc(100% - 3.5em);
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 1px;
        .session-name {
          max-width: 80%;
        }
        .session-button {
          width: 100%;
          border-style: dashed;
        }
      }
    }
    .menu-button {
      padding: 0 24px;
      .ant-btn > .anticon + span {
        margin-left: 0;
      }
    }
    .reset-button {
      width: 100%;
    }
  }
}
