@import "../variables.scss";

#root .history {
  padding: 1em 5% 0;

  @media screen and (max-width: 1200px) {
    padding: 1em 2em 0;
  }

  .container {
    position: relative;

    .absolute {
      position: absolute;
      bottom: 0;
      right: 1em;
    }
  }

  .action.buttons {
    button {
      margin-left: 0.5em;
    }
  }

  // These rules are used for the inline filters (sort by, filter, etc.)
  .ant-btn-link {
    padding: 6px;
    .ant-select-selector {
      padding: 0;
    }
  }

  .entry {
    border-radius: 3px;
    background-color: white;
    position: relative;
    display: flex;
    padding: 1em;
    margin: 0.75em 0;
    border: 1px solid rgba(0, 0, 0, 0.125);

    .request {
      padding-right: 1em;
    }
    .response {
      border-left: 1px dashed $color-grey-dark;
      padding-left: 1em;
    }

    .request,
    .response {
      width: 50%;
      display: flex;
      flex-direction: column;

      .details {
        display: flex;
        align-items: center;

        .ant-tag {
          margin-right: 0;
        }

        .error {
          color: #eb2f96;
        }

        & > span {
          margin-bottom: 1em;
          border-radius: 3px;
          font-size: 0.75rem;
          white-space: nowrap;

          &.date {
            flex: 1 1 auto;
            text-align: right;
            font-weight: bolder;
          }

          & + span {
            margin-left: 0.75em;
          }
        }
      }

      .actions {
        font-size: 0.75rem;
        text-align: right;
      }

      table {
        border-collapse: collapse;
        border-radius: 3px;
        border-style: hidden;
        box-shadow: 0 0 0 1px $color-grey-light;
        width: 100%;
        background-color: rgba($color-white-dark, 0.125);
        font-size: $base-font-size;
        margin-bottom: 1em;
      }

      tr + tr {
        border-top: 1px solid $color-grey-light;
      }

      td {
        width: 50%;
        padding: 0.5em 0.7em;
        word-break: break-all;

        &:nth-child(1) {
          font-weight: bolder;
        }
      }

      .delay {
        flex: 1 1 auto;
        text-align: right;
        margin: 1em 0 0;
        font-size: 0.75rem;
        span {
          font-weight: bolder;
          color: #1890ff;
        }
      }
    }

    .request .details > span {
      &.method {
        border-radius: 3px;
        color: $color-white-light;
        padding: 0.5em;
        background-color: $color-blue-dark;
      }

      &.path {
        font-family: monospace;
      }
    }
  }
}
