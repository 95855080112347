.inline-form-items {
  display: flex;
  align-items: center;
}

.hidden {
  visibility: hidden;
}

.display-none {
  display: none;
}

.ant-form-item {
  margin-bottom: 12px;
}
