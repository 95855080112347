.mermaid {
  & > div:nth-child(1) {
    display: flex;
    & > svg {
      margin: auto;
    }
  }
  .ant-spin {
    margin-left: calc(50% - 1em);
  }
}
