html,
body,
#root,
#root .layout {
  height: 100%;
  position: relative;
}

#root .layout.scrollable {
  overflow-y: auto;
  position: relative;
  .not-scrollable {
    min-height: auto;
  }
}
