.code-editor {
  .CodeMirror {
    position: relative;
    height: auto;
    overflow-y: hidden;
    padding: 0.5em 0 0.5em 0.5em;
    margin: 0;
    border-radius: 3px;

    .CodeMirror-scroll {
      height: auto;
    }

    .CodeMirror-line {
      line-height: 12px;
      font-size: 12px;
    }

    .CodeMirror-gutter-elt {
      line-height: 12px;
      font-size: 14px;
    }
  }
}
