$color-red-light: #fc5c65;
$color-red-dark: #eb3b5a;

$color-orange-light: #fd9644;
$color-orange-dark: #fa8231;

$color-yellow-light: #fed330;
$color-yellow-dark: #f7b731;

$color-green-light: #26de81;
$color-green-dark: #20bf6b;

$color-teal-light: #2bcbba;
$color-teal-dark: #0fb9b1;

$color-blue-light: #45aaf2;
$color-blue-dark: #2d98da;

$color-azure-light: #4b7bec;
$color-azure-dark: #3867d6;

$color-purple-light: #a55eea;
$color-purple-dark: #8854d0;

$color-grey-light: #d1d8e0;
$color-grey-dark: #a5b1c2;

$color-darkgrey-light: #778ca3;
$color-darkgrey-dark: #4b6584;

$color-black-light: #353b48;
$color-black-dark: #2f3640;

$color-white-light: #f5f6fa;
$color-white-dark: #dcdde1;

$base-font-size: 13px;
$button-font-size: 12px;
$base-font-family: Lato, BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto,
  Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
  Helvetica, Arial, sans-serif;
$base-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5);
